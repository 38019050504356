import React from 'react'
import '../styles/pace-loader.css'

// import { Link } from 'gatsby'

import $ from 'jquery';

class Loader extends React.Component {

    componentDidMount() {

        // $(window).scroll(function() {
        //     if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        //         alert("near bottom!");
        //     }
        //  });

        $("#circle").click(function() {
  
            if ($("#circle").hasClass("roll-left")) {
              $( "#item-1" ).toggleClass("show")
              $( "#item-2" ).toggleClass("roll-200")
              $( "#item-3" ).toggleClass("roll-400")
              $( "#item-2" ).toggleClass("roll-right")
              $( "#item-3" ).toggleClass("roll-right")
              $("#circle").toggleClass("roll-right");
            } else {
              $("#circle").toggleClass("roll-left")
              
              $( "#item-1" ).toggleClass("show")
              $( "#item-2" ).toggleClass("roll-200")
              $( "#item-3" ).toggleClass("roll-400")
            }
          }
        )
    }
   
    
    render(){
        return (
            <>
            <div className="pace pace-active" id="circle">
                <div className="pace-progress">
                    <div className="pace-progress-inner">
                        
                    </div>
    
                </div>
                <div className="pace-activity">
    
                </div>
            </div>
    
            <div className="menu" id="menu">
                <ul>
                    <li className="list-item" id="item-1">
                        <a href='#projects'>Projects</a>
                    </li>
                    <li className="list-item" id="item-2">
                        <a href='#about'>About</a>
                    </li>
                    <li className="list-item" id="item-3">
                        <a href='#contact'>Contact</a>
                    </li>
                </ul>
            </div>
            </>
        )
    }
}

export default Loader