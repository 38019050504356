import React from "react"

// Imported Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectCard1 from '../components/cards/projectCard1'
import ProjectCard2 from '../components/cards/projectCard2'
import ProjectCard3 from '../components/cards/projectCard3'
import ProjectCard4 from '../components/cards/projectCard4'
import ProjectCard5 from '../components/cards/projectCard5'
import ContactForm from '../components/contactForm'
import Loader from '../components/loader'
import MenuGit from '../components/menu-git'
import NameSVG from '../components/nameSVG'

//React Reveal imports
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

//React Fade In
import FadeIn from 'react-fade-in';

//React icons
import { FaHandPointRight, FaHandPointUp } from 'react-icons/fa';

//CSS imports
import '../styles/home.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

      <section className="content-section full-screen-height" name="page-top">
    {/* <Fade duration={2000} delay={500} ssrFadeout> */}
    <FadeIn>
        <h1 className="main-header">JOSH <br></br> AKEMAN</h1>
    </FadeIn>
    {/* </Fade> */}
        <NameSVG />
        {/* <h6 className="main-subhead">Full Stack Web Dev</h6> */}
      </section>

    <Fade right>
      <section className="bio-section" name="about">
        <h2>I'm a Full Stack Web Developer with particular specialty in <span className="orangify">Javascript</span>, <span className="orangify">React</span> and <span className="orangify">Golang</span> among other languages and libraries. 
        <br></br><br></br>
        With a background in economics, writing and business ownership as well as interests in Machine Learning, Fintech and sundry topics I'm always learning and expanding my skills.</h2>
      </section>
    </Fade>


    <div className="content-section" name="projects" >
    <Zoom duration={1500}>
      <div className="projects-heading">
        <h1>Projects</h1>
      </div>
    </Zoom>
      <Fade left>
        <section className="each-project-section no-background align-left">
          <ProjectCard1 duration={2500}/>
          <a href="https://it-is-over9000.github.io/ItsOver9000-UI/">
          <div className="visit-project-wrapper">
            <FaHandPointRight color="#fff" size="1.6em"/>
            <h2 className="project-subhead">Visit this Project</h2>
          </div>
          </a>
        </section>
      </Fade>
      <Fade right>
        <section className="each-project-section no-background align-right">
        <ProjectCard2 duration={2500}/>
        <a href="https://chicago-array-build.github.io/chicago-array-build-ui-mike/index.html">
        <div className="visit-project-wrapper">
          <FaHandPointRight color="#fff" size="1.6em"/>
          <h2 className="project-subhead">Visit this Project</h2>
        </div>
        </a>
        </section>
      </Fade>
      <Fade left>
        <section className="each-project-section no-background align-left">
        <ProjectCard3 duration={2500}/>
        <a href="https://www.youtube.com/watch?v=0JfRVZaGeSQ&t=1s">
        <div className="visit-project-wrapper">
          <FaHandPointRight color="#fff" size="1.6em"/>
          <h2 className="project-subhead">Visit this Project</h2>
        </div>
        </a>
        </section>
      </Fade>
      <Fade right>
        <section className="each-project-section no-background align-left">
        <ProjectCard4 duration={2500}/>
        <a href="https://flamboyant-blackwell-be93e6.netlify.app/">
        <div className="visit-project-wrapper">
          <FaHandPointRight color="#fff" size="1.6em"/>
          <h2 className="project-subhead">Visit this Project</h2>
        </div>
        </a>
        </section>
      </Fade>
      <Fade left>
        <section className="each-project-section no-background align-left">
        <ProjectCard5 duration={2500}/>
        <a href="https://www.foundstages.org">
        <div className="visit-project-wrapper">
          <FaHandPointRight color="#fff" size="1.6em"/>
          <h2 className="project-subhead">Visit this Project</h2>
        </div>
        </a>
        </section>
      </Fade>

      <Zoom duration={1200}>
        <ContactForm />
      
      <a href="#page-top">
        <div className="visit-project-wrapper">
          <FaHandPointUp color="#fff" size="1.6em"/>
          <h2 className="project-subhead">Scroll To The Top</h2>
        </div>
        </a>
      </Zoom>
    </div>

    {/* <Loader /> */}
    <MenuGit />

  </Layout>
)

export default IndexPage
