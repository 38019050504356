import React from 'react'

import '../styles/contact-form.css'
import $ from 'jquery'

class ContactForm extends React.Component {

    state = {
        name: '',
        email: ''
    }

    componentDidMount() {
        $( '.js-input' ).keyup(function() {
            if( $(this).val() ) {
               $(this).addClass('not-empty');
            } else {
               $(this).removeClass('not-empty');
            }
          });
    }

    render() {
        return (
            <div className="contact-form-container">
                <h2>Get in Touch</h2>
                <form name="contact" method="POST" data-netlify="true" className="contact-form row">
                <div class="form-field col x-50">
                    <input id="name" className="input-text js-input" type="text" required/>
                    <label className="label" for="name">Name</label>
                </div>
                <div className="form-field col x-50">
                    <input id="email" className="input-text js-input" type="email" required/>
                    <label className="label" for="email">E-mail</label>
                </div>
                <div className="form-field col x-100">
                    <input id="message" className="input-text js-input" type="text" required/>
                    <label className="label" for="message">Message</label>
                </div>
                <div className="form-field col x-100 align-center">
                    <input className="submit-btn" type="submit" value="Submit"/>
                </div>
                </form>
            </div>
           )
    }
}

export default ContactForm
