import React from 'react'

import '../styles/name-svg.css'

export default function nameSVG() {
    return (
        <svg width="450" height="47" viewBox="0 0 533 47" fill="transparent" xmlns="http://www.w3.org/2000/svg" id="my-svg">
        <path d="M0.536011 45H6.29601V24.624H23.072V19.8H6.29601V6.62398H26.672V1.79998H0.536011V45Z" stroke="#FF8600"/>
        <path d="M42.1396 45.864C47.3236 45.864 51.9316 42.768 54.2356 39.024L54.5956 45H59.3476V13.896H53.8756V32.256C53.8756 37.296 48.1156 41.184 43.6516 41.184C39.1156 41.184 36.3796 38.88 36.3796 31.896V13.896H30.9076V32.4C30.9076 40.104 33.8596 45.864 42.1396 45.864Z" stroke="#FF8600"/>
        <path d="M68.2453 45H73.7173V1.79998H68.2453V45Z" stroke="#FF8600"/>
        <path d="M82.6593 45H88.1313V1.79998H82.6593V45Z" stroke="#FF8600"/>
        <path d="M123.235 45.936C130.651 45.936 137.707 41.616 137.707 33.192C137.707 26.064 133.459 22.032 124.171 19.584C117.403 17.784 115.459 15.192 115.459 12.096C115.459 8.06398 118.555 5.54398 123.955 5.54398C127.483 5.54398 131.515 7.27198 134.395 9.35998L135.691 4.82398C133.315 2.87998 128.923 0.863983 123.955 0.863983C115.099 0.863983 110.059 5.97598 110.059 12.096C110.059 18.144 113.947 22.536 122.083 24.696C130.147 26.856 132.307 29.304 132.307 33.696C132.307 38.376 128.347 41.256 123.235 41.256C117.043 41.256 112.723 38.16 110.131 35.352L108.835 40.68C111.211 43.128 116.179 45.936 123.235 45.936Z" stroke="#FF8600"/>
        <path d="M148.349 45H153.821V17.64H160.877V13.896H153.821V5.39999H148.349V13.896H141.365V17.64H148.349V45Z" stroke="#FF8600"/>
        <path d="M179.574 45.864C184.686 45.864 189.222 43.344 191.814 39.384L192.03 45H196.782V13.896H192.03L191.814 19.512C189.222 15.552 184.686 13.032 179.574 13.032C171.006 13.032 164.742 20.376 164.742 29.448C164.742 38.52 171.006 45.864 179.574 45.864ZM180.942 41.472C175.038 41.472 170.214 36.936 170.214 29.448C170.214 21.96 175.038 17.424 180.942 17.424C186.846 17.424 191.67 21.96 191.67 29.448C191.67 36.936 186.846 41.472 180.942 41.472Z" stroke="#FF8600"/>
        <path d="M220.178 45.864C224.282 45.864 227.522 45.144 231.05 42.696V37.728C228.098 40.464 224.282 41.4 220.682 41.4C213.986 41.4 209.45 36.432 209.45 29.448C209.45 22.608 213.986 17.496 220.682 17.496C224.282 17.496 228.098 18.432 231.05 21.168V16.2C227.522 13.752 224.282 13.032 220.178 13.032C210.746 13.032 203.978 19.728 203.978 29.448C203.978 39.168 210.746 45.864 220.178 45.864Z" stroke="#FF8600"/>
        <path d="M238.12 45H243.592V28.872L258.64 45H265.768L249.352 27.792L265.192 13.896H258.424L243.592 27.144V1.79998H238.12V45Z" stroke="#FF8600"/>
        <path d="M295.186 45H298.786L309.01 15.408C309.226 14.832 309.658 13.176 309.73 13.032C309.802 13.248 310.234 14.832 310.45 15.408L320.674 45H324.274L339.178 1.79998H333.562L323.194 33.696C322.906 34.488 322.618 36.36 322.618 36.36C322.618 36.36 322.186 34.488 321.898 33.696L311.674 1.79998H308.074L297.85 33.696C297.562 34.488 297.13 36.144 297.13 36.36C297.058 36.144 296.77 34.416 296.554 33.696L286.186 1.79998H280.282L295.186 45Z" stroke="#FF8600"/>
        <path d="M354.321 45.864C358.857 45.864 363.465 44.568 366.417 42.192V37.368C364.041 39.672 359.721 41.4 356.193 41.4C346.257 41.4 343.665 34.128 344.097 28.656H366.561C366.777 27.576 366.993 26.208 366.993 25.128C366.993 18.072 361.521 13.032 353.745 13.032C346.113 13.032 338.697 19.224 338.697 29.232C338.697 38.88 345.177 45.864 354.321 45.864ZM344.601 24.84C345.537 19.728 349.569 17.352 353.961 17.352C359.073 17.352 362.385 20.664 361.737 24.84H344.601Z" stroke="#FF8600"/>
        <path d="M391.734 45.864C400.302 45.864 406.566 38.52 406.566 29.448C406.566 20.376 400.302 13.032 391.734 13.032C386.91 13.032 382.591 15.264 379.999 18.792V1.79998H374.526V45H379.279L379.495 39.384C382.087 43.344 386.622 45.864 391.734 45.864ZM390.366 41.472C384.462 41.472 379.638 36.936 379.638 29.448C379.638 21.96 384.462 17.424 390.366 17.424C396.27 17.424 401.095 21.96 401.095 29.448C401.095 36.936 396.27 41.472 390.366 41.472Z" stroke="#FF8600"/>
        <path d="M427.684 45H441.076C457.852 45 465.124 35.28 465.124 23.4C465.124 11.52 457.852 1.79998 441.076 1.79998H427.684V45ZM433.444 39.96V6.83998H441.148C455.188 6.83998 459.148 14.76 459.148 23.4C459.148 32.04 455.188 39.96 441.148 39.96H433.444Z" stroke="#FF8600"/>
        <path d="M485.946 45.864C490.482 45.864 495.09 44.568 498.042 42.192V37.368C495.666 39.672 491.346 41.4 487.818 41.4C477.882 41.4 475.29 34.128 475.722 28.656H498.186C498.402 27.576 498.618 26.208 498.618 25.128C498.618 18.072 493.146 13.032 485.37 13.032C477.738 13.032 470.322 19.224 470.322 29.232C470.322 38.88 476.802 45.864 485.946 45.864ZM476.226 24.84C477.162 19.728 481.194 17.352 485.586 17.352C490.698 17.352 494.01 20.664 493.362 24.84H476.226Z" stroke="#FF8600"/>
        <path d="M514.808 45.072H518.48L532.16 13.896H526.832L516.824 37.224L506.744 13.896H501.128L514.808 45.072Z" stroke="#FF8600"/>
        </svg>
    )
}
