import React from 'react'

import '../../styles/project-card.css'

import { FaGithub, FaAngleDoubleRight, FaUndo } from 'react-icons/fa';

import $ from 'jquery';

import javascript from '../../images/icons2/javascript.png'
import react from '../../images/icons2/react.png'
import gatsby from '../../images/icons2/gatsby.png'
import graphql from '../../images/icons2/graphql.png'
import foundstages from '../../images/screenshots/foundstages.jpg'

class ProjectCard1 extends React.Component {
    
    componentDidMount() {
        
        $('#arrow-5').click(function(){
            $(".card-wrapper-5").addClass('grow-shrink');
            $(".card-wrapper-5").removeClass('reverse-shrink');
            $(".name-badge-5").toggleClass('shrink');
            $(".top-5").toggleClass('add-flip-right');
            $(".back-top-5").toggleClass('grow');
            $(".bottom-5").toggleClass('add-flip-left');
            $(".back-bottom-5").toggleClass('grow');
            $(".flip-back-5").removeClass('hidden')
          });

          $('#clickBox-5').click(function(){

            $(".card-wrapper-5").addClass('reverse-shrink');
           $(".card-wrapper-5").removeClass('grow-shrink');
           $(".name-badge-5").toggleClass('shrink');
           $(".top-5").toggleClass('add-flip-right');
           $(".back-top-5").toggleClass('grow');
           $(".bottom-5").toggleClass('add-flip-left');
           $(".back-bottom-5").toggleClass('grow');
           $(".flip-back-5").toggleClass('hidden')
           // $("#front").toggleClass('vanish');
           // $("#back").toggleClass('disappear');
         });
    }

    render() {

        return (
            <>
            {/* <div id="clickBox-5">click box</div> */}
            <div className="flip-back-5 hidden" id='clickBox-5'>
                <FaUndo color="#ff8600"/>
                <p>flip to front</p>
            </div>
            <div className="card-wrapper-5">
            <div className="card">
                <div id="back">
                <div className="back-top-5">
                <h2 className="center-text white-text">Screenshot</h2>
                        <img src={foundstages} alt="Found Stages screenshot"/>
                        <p>
                            I designed and maintain the website for this amazing, innovative Atlanta theater company using the Gatsby framework built on React and GraphQL.
                        </p>
                    </div>
                    <div className="back-bottom-5">
                    </div>
                </div>
                <div id="front">
    
                    <div className="top-5">
                    
                    </div>
                    <div className="name-badge-5">
                        <h2>Found Stages</h2>
                    </div>
    
                    <div className="bottom-5">
                        <div className="github-link">
                            {/* <i class="fab fa-github-square"></i> */}
                            {/* <FontAwesomeIcon icon={faCoffee} /> */}
                            <FaGithub  className="github-icon" />
                            <p>Project Github</p>
                        </div>
                        <div id="arrow-5">
                            <FaAngleDoubleRight />
                            <p>flip to back</p>
                        </div>
                        <h2>The website for the Found Stages theater company in Atlanta, built with the blazing fast Gatsby framework. </h2>
                        
                        <div className="icon-badge-container">
                            <h3>Tech Stack</h3>
                            <div className="icon-badge-row-wrapper">
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={javascript} alt="" />
                                </div>
                                    <p className="icon-text">Javascript</p>
                                </div>
                            
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                        <img src={react} alt="" />
                                    </div>
                                    <p className="icon-text">React</p>
                                </div>
                        
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={gatsby} alt="" />
                                </div>
                                <p className="icon-text">Gatsby</p>
                                </div>
                                
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={graphql} id="sql" alt="" />
                                </div>
                                <p className="icon-text">GraphQL</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }
    
}

export default ProjectCard1