import React from 'react'

import '../../styles/project-card.css'

import { FaGithub, FaAngleDoubleRight, FaUndo } from 'react-icons/fa';

import $ from 'jquery';

import javascript from '../../images/icons2/javascript.png'
import react from '../../images/icons2/react.png'
import node from '../../images/icons2/node.png'
import postgres from '../../images/icons2/postgres.png'
import delphe from '../../images/screenshots/delphe.jpg'

class ProjectCard1 extends React.Component {
    
    componentDidMount() {
        
        $('#arrow-4').click(function(){
            $(".card-wrapper-4").addClass('grow-shrink');
            $(".card-wrapper-4").removeClass('reverse-shrink');
            $(".name-badge-4").toggleClass('shrink');
            $(".top-4").toggleClass('add-flip-right');
            $(".back-top-4").toggleClass('grow');
            $(".bottom-4").toggleClass('add-flip-left');
            $(".back-bottom-4").toggleClass('grow');
            $(".flip-back-4").removeClass('hidden')

          });

          $('#clickBox-4').click(function(){

            $(".card-wrapper-4").addClass('reverse-shrink');
           $(".card-wrapper-4").removeClass('grow-shrink');
           $(".name-badge-4").toggleClass('shrink');
           $(".top-4").toggleClass('add-flip-right');
           $(".back-top-4").toggleClass('grow');
           $(".bottom-4").toggleClass('add-flip-left');
           $(".back-bottom-4").toggleClass('grow');
           $(".flip-back-4").toggleClass('hidden')
           // $("#front").toggleClass('vanish');
           // $("#back").toggleClass('disappear');
         });
    }

    render() {

        return (
            <>
            {/* <div id="clickBox-4">click box</div> */}
            <div className="flip-back-4 hidden" id='clickBox-4'>
                <FaUndo color="#ff8600"/>
                <p>flip to front</p>
            </div>
            <div className="card-wrapper-4">
            <div className="card">
                <div id="back">
                <div className="back-top-4">
                <h2 className="center-text white-text">Screenshot</h2>
                        <img src={delphe} alt="Delphe App screenshot"/>
                        <p>
                            Explore the user-friendly functionality of Delphe version 1.0.
                        </p>
                    </div>
                    <div className="back-bottom-4">
                       
                    </div>
                </div>
                <div id="front">
    
                    <div className="top-4">
                    
                    </div>
                    <div className="name-badge-4">
                        <h2>Delphe</h2>
                    </div>
    
                    <div className="bottom-4">
                        <a href="https://github.com/labs13-delphe" >
                            <div className="github-link">
                                {/* <i class="fab fa-github-square"></i> */}
                                {/* <FontAwesomeIcon icon={faCoffee} /> */}
                                <FaGithub  className="github-icon" />
                                <p>Project Github</p>
                            </div>
                        </a>
                        <div id="arrow-4">
                            <FaAngleDoubleRight />
                            <p>flip to back</p>
                        </div>
                        <h2>Like Quora taken to a deeper level, Delphe is a marketplace for one-on-one connections to experts in a wide range of topics</h2>
                        
                        <div className="icon-badge-container">
                            <h3>Tech Stack</h3>
                            <div className="icon-badge-row-wrapper">
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={javascript} alt="" />
                                </div>
                                    <p className="icon-text">Javascript</p>
                                </div>
                            
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                        <img src={react} alt="" />
                                    </div>
                                    <p className="icon-text">React</p>
                                </div>
                        
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={node} alt="" />
                                </div>
                                <p className="icon-text">Node.js</p>
                                </div>
                                
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={postgres} id="sql" alt="" />
                                </div>
                                <p className="icon-text">Postgres</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }
    
}

export default ProjectCard1