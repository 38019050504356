import React from 'react'

import '../../styles/project-card.css'

import { FaGithub, FaAngleDoubleRight, FaUndo } from 'react-icons/fa';

import $ from 'jquery';

import javascript from '../../images/icons2/javascript.png'
import react from '../../images/icons2/react.png'
import node from '../../images/icons2/node.png'
import postgres from '../../images/icons2/postgres.png'

class ProjectCard1 extends React.Component {
    
    componentDidMount() {
        
        $('#arrow-3').click(function(){
            $(".card-wrapper-3").addClass('grow-shrink');
            $(".card-wrapper-3").removeClass('reverse-shrink');
            $(".name-badge-3").toggleClass('shrink');
            $(".top-3").toggleClass('add-flip-right');
            $(".back-top-3").toggleClass('grow');
            $(".bottom-3").toggleClass('add-flip-left');
            $(".back-bottom-3").toggleClass('grow');
            $(".flip-back-3").removeClass('hidden')

          });

          $('#clickBox-3').click(function(){

            $(".card-wrapper-3").addClass('reverse-shrink');
           $(".card-wrapper-3").removeClass('grow-shrink');
           $(".name-badge-3").toggleClass('shrink');
           $(".top-3").toggleClass('add-flip-right');
           $(".back-top-3").toggleClass('grow');
           $(".bottom-3").toggleClass('add-flip-left');
           $(".back-bottom-3").toggleClass('grow');
           $(".flip-back-3").toggleClass('hidden')
           // $("#front").toggleClass('vanish');
           // $("#back").toggleClass('disappear');
         });
    }

    render() {

        return (
            <>
            {/* <div id="clickBox-3">click box</div> */}
            <div className="flip-back-3 hidden" id='clickBox-3'>
                <FaUndo color="#ff8600"/>
                <p>flip to front</p>
            </div>
            <div className="card-wrapper-3">
            <div className="card">
                <div id="back">
                <div className="back-top-3">
                <h2 className="center-text white-text">See the Video</h2>
                <iframe width="300" height="250" src="https://www.youtube.com/embed/0JfRVZaGeSQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <p>
                            This app was built for a 48-hour-hackathon and is an ongoing project moving toward official release. For now, check out the hype video!
                        </p>
                    </div>
                    <div className="back-bottom-3">
                    </div>
                </div>
                <div id="front">
    
                    <div className="top-3">
                    
                    </div>
                    <div className="name-badge-3">
                        <h2>Satoshi's Law</h2>
                    </div>
    
                    <div className="bottom-3">
                        <a href="https://github.com/satoshi-s-law" >
                            <div className="github-link">
                                {/* <i class="fab fa-github-square"></i> */}
                                {/* <FontAwesomeIcon icon={faCoffee} /> */}
                                <FaGithub  className="github-icon" />
                                <p>Project Github</p>
                            </div>
                        </a>
                        <div id="arrow-3">
                            <FaAngleDoubleRight />
                            <p>flip to back</p>
                        </div>
                        <h2>A web app integrated with bitcoin's Lightning Network that helps self-employed bitcoiners track billable hours and get paid via lightning.</h2>
                        
                        <div className="icon-badge-container">
                            <h3>Tech Stack</h3>
                            <div className="icon-badge-row-wrapper">
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={javascript} alt="" />
                                </div>
                                    <p className="icon-text">Javascript</p>
                                </div>
                            
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                        <img src={react} alt="" />
                                    </div>
                                    <p className="icon-text">React</p>
                                </div>
                        
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={node} alt="" />
                                </div>
                                <p className="icon-text">Node.js</p>
                                </div>
                                
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={postgres} id="sql" alt="" />
                                </div>
                                <p className="icon-text">Postgres</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }
    
}

export default ProjectCard1