import React from 'react'

import '../../styles/project-card.css'

import { FaGithub, FaAngleDoubleRight, FaUndo } from 'react-icons/fa';

import $ from 'jquery';

import javascript from '../../images/icons2/javascript.png'
import react from '../../images/icons2/react.png'
import node from '../../images/icons2/node.png'
import postgres from '../../images/icons2/postgres.png'
import aot from '../../images/screenshots/aot.jpg'


class ProjectCard1 extends React.Component {
    
    componentDidMount() {
        
        $('#arrow-2').click(function(){
            $(".card-wrapper-2").addClass('grow-shrink');
            $(".card-wrapper-2").removeClass('reverse-shrink');
            $(".name-badge-2").toggleClass('shrink');
            $(".top-2").toggleClass('add-flip-right');
            $(".back-top-2").toggleClass('grow');
            $(".bottom-2").toggleClass('add-flip-left');
            $(".back-bottom-2").toggleClass('grow');
            $(".flip-back-2").removeClass('hidden')

          });

          $('#clickBox-2').click(function(){

            $(".card-wrapper-2").addClass('reverse-shrink');
           $(".card-wrapper-2").removeClass('grow-shrink');
           $(".name-badge-2").toggleClass('shrink');
           $(".top-2").toggleClass('add-flip-right');
           $(".back-top-2").toggleClass('grow');
           $(".bottom-2").toggleClass('add-flip-left');
           $(".back-bottom-2").toggleClass('grow');
           $(".flip-back-2").toggleClass('hidden')
           // $("#front").toggleClass('vanish');
           // $("#back").toggleClass('disappear');
         });
    }

    render() {

        return (
            <>
            <div className="flip-back-2 hidden" id='clickBox-2'>
                <FaUndo color="#ff8600"/>
                <p>flip to front</p>
            </div>
            {/* <div id="clickBox-2">click box</div> */}
            <div className="card-wrapper-2">
            <div className="card">
                <div id="back">
                    <div className="back-top-2">
                        <h2 className="center-text white-text">Screenshot</h2>
                        <img src={aot} alt="Chicago Array of Things App screenshot"/>
                        <p>
                            Explore a variety of realtime data visualizations straight from the Chicago Array of Things API. 

                            To sign in to the app simply use credentials 'username' and 'password'
                        </p>
                    </div>
                    <div className="back-bottom-2">

                    </div>
                </div>
                <div id="front">
    
                    <div className="top-2">
                    
                    </div>
                    <div className="name-badge-2">
                        <h2>Chicago Array of Things</h2>
                    </div>
    
                    <div className="bottom-2">
                        <a href="https://github.com/chicago-array-build" >
                            <div className="github-link">
                                {/* <i class="fab fa-github-square"></i> */}
                                {/* <FontAwesomeIcon icon={faCoffee} /> */}
                                <FaGithub  className="github-icon" />
                                <p>Project Github</p>
                            </div>
                        </a>
                        <div id="arrow-2">
                            <FaAngleDoubleRight />
                            <p>flip to back</p>
                        </div>
                        <h2>A public-friendly interface that allows anyone to explore the real-time urban data made available through Chicago's innovative Array of Things API. </h2>
                        
                        <div className="icon-badge-container">
                            <h3>Tech Stack</h3>
                            <div className="icon-badge-row-wrapper">
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={javascript} alt="" />
                                </div>
                                    <p className="icon-text">Javascript</p>
                                </div>
                            
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                        <img src={react} alt="" />
                                    </div>
                                    <p className="icon-text">React</p>
                                </div>
                        
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={node} alt="" />
                                </div>
                                <p className="icon-text">Node.js</p>
                                </div>
                                
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={postgres} id="sql" alt="" />
                                </div>
                                <p className="icon-text">Postgres</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }
    
}

export default ProjectCard1