import React from "react";
import ReactDOM from "react-dom";
import classNames from 'classnames'

import "../styles/menu-git.css";

class MenuGit extends React.Component {

  state = {
    menuOpen: false,
    beenClicked: 0
  }

  toggleMenu = () => {
    console.log(this.state.beenClicked)
    this.setState({
      menuOpen: !this.state.menuOpen,
      beenClicked: this.state.beenClicked + 1
    })
    console.log(this.state.beenClicked)
  }

  render() {

    var pathClass1 = classNames({
      'add-dash': this.state.menuOpen && this.state.beenClicked % 2 === 1,
      'add-undash': !this.state.menuOpen && this.state.beenClicked % 2 === 0 && this.state.beenClicked>0
    })
    var pathClass2 = classNames({
      'add-dash-2': this.state.menuOpen && this.state.beenClicked  % 2 === 1,
      'add-undash-2': !this.state.menuOpen && this.state.beenClicked % 2 === 0 && this.state.beenClicked>0
    })
    var circleClass1 = classNames({
      'circle-1': true,
      'add-grow': this.state.menuOpen && this.state.beenClicked  % 2 === 1,
      'add-shrink': !this.state.menuOpen && this.state.beenClicked % 2 === 0 && this.state.beenClicked>0
    })
    var circleClass = classNames({
      'circle': true,
      'add-grow': this.state.menuOpen && this.state.beenClicked  % 2 === 1,
      'add-shrink': !this.state.menuOpen && this.state.beenClicked % 2 === 0 && this.state.beenClicked>0
    })
    return (
      <div className="menugit-wrapper">
        <svg
        width="600px"
        height="800px"
        viewBox="0 0 210 297"
        version="1.1"
        id="svg8"
        >
    
          <g id="layer1">
            <path
              d="M 167.06547,114.05952 H 72.949405"
              id="path3713"
              className={pathClass1} 
                  />
          </g>
          <g id="layer2">
            <path
              d="m 93.715178,143.39589 c 51.278722,0 46.727362,-27.14677 46.727362,-27.14677"
              id="path4559"
              className = {pathClass2} 
                />
          </g>
        </svg>
  
  <div 
    className={circleClass} 
    id="click-circle"
    >
    <a href='#projects'><h3>Projects</h3></a>
  </div>
  <div 
    className={circleClass1} 
    id="circle1"
    >
    <a href='#contact'><h3>Contact</h3></a>
  </div>
  <div className="circle-2" id="circle2" onClick={this.toggleMenu}></div>
  
  </div>
    );
  }
}

export default MenuGit