import React from 'react'

import '../../styles/project-card.css'

import { FaGithub, FaAngleDoubleRight, FaUndo } from 'react-icons/fa';

import $ from 'jquery';

import javascript from '../../images/icons2/javascript.png'
import react from '../../images/icons2/react.png'
import node from '../../images/icons2/node.png'
import postgres from '../../images/icons2/postgres.png'
import over9000 from '../../images/screenshots/over-9000.jpg'


class ProjectCard1 extends React.Component {
    
    componentDidMount() {
        
        $('#arrow-1').click(function(){
            $(".card-wrapper-1").addClass('grow-shrink');
            $(".card-wrapper-1").removeClass('reverse-shrink');
            $(".name-badge-1").toggleClass('shrink');
            $(".top-1").toggleClass('add-flip-right');
            $(".back-top-1").toggleClass('grow');
            $(".bottom-1").toggleClass('add-flip-left');
            $(".back-bottom-1").toggleClass('grow');
            $(".flip-back-1").removeClass('hidden')

          });

          $('#clickBox-1').click(function(){

            $(".card-wrapper-1").addClass('reverse-shrink');
           $(".card-wrapper-1").removeClass('grow-shrink');
           $(".name-badge-1").toggleClass('shrink');
           $(".top-1").toggleClass('add-flip-right');
           $(".back-top-1").toggleClass('grow');
           $(".bottom-1").toggleClass('add-flip-left');
           $(".back-bottom-1").toggleClass('grow');
           $(".flip-back-1").toggleClass('hidden')
           // $("#front").toggleClass('vanish');
           // $("#back").toggleClass('disappear');
         });
    }

    render() {

        return (
            <>
            <div className="flip-back-1 hidden" id='clickBox-1'>
                <FaUndo color="#ff8600"/>
                <p>flip to front</p>
            </div>
            {/* <div id="clickBox-1">click box</div> */}
            <div className="card-wrapper-1">
            <div className="card">
                <div id="back">
                <div className="back-top-1">
                        <h2 className="center-text white-text">Screenshot</h2>
                        <img src={over9000} alt="It's Over 9000! App screenshot"/>
                        <p>
                            Plug this FEN string (or use your own!) into the It's Over 9000 app to give it a whirl:
                            <br></br><br></br>
                            '5k2/ppp5/4P3/3R3p/6P1/1K2Nr2/PP3P2/8 b - - 1 32'
                        </p>
                    </div>
                    <div className="back-bottom-1">
                    </div>
                </div>
                <div id="front">
    
                    <div className="top-1">
                    
                    </div>
                    <div className="name-badge-1">
                        <h2>It's Over 9000!</h2>
                    </div>
    
                    <div className="bottom-1">
                        <a href="https://github.com/it-is-over9000" >
                            <div className="github-link">
                                {/* <i class="fab fa-github-square"></i> */}
                                {/* <FontAwesomeIcon icon={faCoffee} /> */}
                                <FaGithub  className="github-icon" />
                                <p>Project Github</p>
                            </div>
                        </a>
                        <div id="arrow-1">
                            <FaAngleDoubleRight />
                            <p>flip to back</p>
                        </div>
                        <h2>A smarter way to improve your chess game. Level up your game with instant analysis from a deep neural network trained on hundreds of thousands of chess games.</h2>
                        
                        <div className="icon-badge-container">
                            <h3>Tech Stack</h3>
                            <div className="icon-badge-row-wrapper">
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={javascript} alt="" />
                                </div>
                                    <p className="icon-text">Javascript</p>
                                </div>
                            
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                        <img src={react} alt="" />
                                    </div>
                                    <p className="icon-text">React</p>
                                </div>
                        
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={node} alt="" />
                                </div>
                                <p className="icon-text">Node.js</p>
                                </div>
                                
                                <div className="icon-badge-wrapper">
                                    <div className="icon-badge">
                                    <img src={postgres} id="sql" alt="" />
                                </div>
                                <p className="icon-text">Postgres</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }
    
}

export default ProjectCard1